import { chain, filter, find, findIndex, findKey, includes, intersection, isEmpty, isString, keys } from "lodash";
import moment, { Moment } from "moment";
import { default as momentTz } from "moment-timezone";
import functionStatus from "../files/functionStatus.json";
import statuses from "../files/status.json";
import { BookingType, ProductCategoryId } from "../types";
import { Booking } from "../types/Booking";
import { FunctionBooking } from "../types/FunctionBooking";
import { OpeningTimes } from "../types/Seating";
import { GenerationType, VoucherSetup, VoucherTypes } from "../types/Voucher";
import { Customer } from "../types/customer";
import { LineItem, PayLaters } from "../types/transaction";
import { getAllBookingTables, getBillAvailableAndUnpaidCustomerId, getOpeningTimesAdjusted, getPayBillNowStatus, getTicks, isTickInBetween } from "../utils";

export function isStatusEqualByBooking(booking: Booking | FunctionBooking, ...text: ("arrived" | "seated" | "confirmed" | "vacated" | "cancelled" | "noShow" | "onHold" | "lost" | "denied" | "discardedDate" | "inProgress" | "depositRequested" | "postponed")[]) {
  if (!booking) return false;
  if ("functionName" in booking) {
    for (let i = 0; i < text.length; i++) {
      if (text[i] in functionStatus && booking.status === functionStatus[text[i]]?.value) return true;
    }
    return false;
  }
  for (let i = 0; i < text.length; i++) {
    if (text[i] in statuses && booking.status === statuses[text[i]]?.value) return true;
  }
  return false;
}

export function getStatusValueByBooking(booking: Booking | FunctionBooking, ...text: ("arrived" | "seated" | "confirmed" | "vacated" | "cancelled" | "noShow" | "onHold" | "lost" | "denied" | "discardedDate")[]) {
  if (!booking) return false;
  if ("functionName" in booking) {
    for (let i = 0; i < text.length; i++) {
      if (text[i] in functionStatus) return functionStatus[text[i]]?.value;
    }
    return 0;
  }
  for (let i = 0; i < text.length; i++) {
    if (text[i] in statuses && statuses[text[i]]?.value) return statuses[text[i]]?.value;
  }
  return 0;
}

export function generateReadonlyVoucherFormData(voucherType: VoucherTypes, restaurantId: string, bookingId: string, bookingRef: string, customer?: Customer, customerId?: string): Partial<VoucherSetup> {
  switch (voucherType) {
    case VoucherTypes.DeferredBookingVoucher:
      return {
        bookingId: bookingId,
        name: `Deferred Booking Voucher${bookingRef ? ` for booking ref: ${bookingRef}` : ""}`,
        description: "Voucher for customers that have cancelled their booking during the no penalty period.",
        voucherType: VoucherTypes.DeferredBookingVoucher,
        type: "oneOff",
        discountType: "$",
        generationType: GenerationType.oneOff,
        restaurants: [restaurantId],
        allProducts: true,
        isEnabled: true,
        sendEmail: true,
        customerFilters: {
          filterType: "specific",
          selectedFilters: [],
          membershipLevels: [],
          age: {
            minAge: "",
            maxAge: "",
          },
          birthdayMonth: "",
          specificCustomers: [],
          specificCustomerObjects: [
            {
              label: `${customer?.lastName} ${customer?.firstName} (${customer?.email})`,
              value: customerId || "",
            },
          ],
        },
      };

    default:
      return {};
  }
}

export function getInService(now: Moment, date = "", openingTimes: OpeningTimes) {
  let inService = false;
  const { open, close } = getOpeningTimesAdjusted(openingTimes.open, openingTimes.close);

  if (!date) {
    return isTickInBetween(open, close, getTicks(now));
  }
  if (now.date() === moment(date, "YYYYMMDD").date() && getTicks(now) >= Number(open) && getTicks(now) <= Number(close)) {
    inService = true;
  } else if (now.date() === moment(date, "YYYYMMDD").add(1, "days").date()) {
    const nowPlus24 = getTicks(now) + 24 * 60 * 60 * 1000;
    if (nowPlus24 <= Number(close)) {
      inService = true;
    }
  }
  return inService;
}

/** Check if booking status change is allowed based on pending pay later/ now bills */
export const validateBookingStatusChange = (bookingId: string, payLaters: PayLaters) => {
  const [hasPayBillLater] = getBillAvailableAndUnpaidCustomerId(payLaters, bookingId);
  const [hasPayBillNow] = getPayBillNowStatus(payLaters, bookingId);
  if (hasPayBillLater || hasPayBillNow) {
    throw new Error("Bill is still pending for this booking");
  }
  return true;
};

// get conflicted seated bookings when attempting to seat a booking
export function getAnyConflictedSeatedBookings(bookings: (Booking | FunctionBooking)[], booking: Booking | FunctionBooking) {
  const results: (Booking | FunctionBooking)[] = [];
  const tables = getAllBookingTables(booking);
  // const booking = find(bookings, (b) => b._key === bookingId);
  if (booking && isStatusEqualByBooking(booking, "seated")) {
    for (let i = 0; i < bookings.length; i++) {
      const booking2 = bookings[i];
      if (booking._key !== booking2._key && isStatusEqualByBooking(booking2, "seated")) {
        if (booking2.alg.tables) {
          if (intersection(keys(tables), keys(getAllBookingTables(booking2))).length > 0 || intersection(booking.tableOverride, keys(getAllBookingTables(booking2))).length > 0) {
            results.push(booking2);
          }
        }
      }
    }
  }
  return results;
}

/**
 * Computes number of milliseconds from given date, interval value and zone id
 *
 * @param {number} date     eg 20240627
 * @param {number} interval eg 53100000 which is 2:45 PM
 * @param {string} zoneId   eg "Australia/Sydney"
 * @returns {number} number of milliseconds
 */
export const getMillisecondsFromDateInterval = (date: number, interval: number, zoneId: string) => {
  if (!isValidDateFormat(String(date))) throw new Error("Expected date to be in YYYYMMDD format");

  return momentTz.tz(String(date), "YYYYMMDD", zoneId).startOf("day").add(interval, "milliseconds").valueOf();
};

/**
 * Validates if given string is in YYYYMMDD format
 * @param {string} dateString
 * @returns {boolean}
 */
export const isValidDateFormat = (dateString: string): boolean => {
  if (!isString(dateString) || !/^\d{8}$/.test(dateString)) {
    return false;
  }
  return moment(dateString, "YYYYMMDD").isValid();
};

export const getPayLaterLineItemsForBookingMenuFullPayment = (payLaters: PayLaters, bookingId: string, menuId: string): LineItem[] => {
  if (isEmpty(payLaters) || !menuId) return [];
  const filteredPayLaters = filter(payLaters, (pl) => pl.bookingId === bookingId);
  if (filteredPayLaters.length === 0) {
    return [];
  }
  return filteredPayLaters.map((payLater) => filter(payLater.paymentSummary?.lineItems, (lineItem) => lineItem.productCategoryId === ProductCategoryId.PrepaidMenus && lineItem.id1 === menuId)).flat();
};

export const getUserTableNumber = (booking: Booking | FunctionBooking, userId: string): string => {
  if (!booking) return "";
  if (booking?.customerId === userId && booking?.tableNumber) return booking.tableNumber;
  const guest = find(booking.guests, (user) => user.customerId === userId);
  if (guest) {
    return guest.tableNumber;
  }
  return booking?.tableNumber || booking?.alg?.number || "";
};

export const findBatchingTableGroupIdByUser = (booking: Booking | FunctionBooking, userId: string): string => {
  if (booking.type === BookingType.QROrderingOnly) return booking.menuId;

  if (!booking.batchingTableGroups) return null;

  const userTableNumber = getUserTableNumber(booking, userId);
  if (!userTableNumber) return null;

  if (booking.batchingTableGroups) {
    return findKey(booking.batchingTableGroups, (value) => includes(value, userTableNumber));
  }

  /**
   * @todo add logic to handle proximity and section tables
   * This apply to  non exclusive functions and possibly for standard bookings.
   * Tables may not neccesarily be grouped per batchingTableGroups. For now batching is done per booking.
   */

  return null;
};

export function isFunctionBooking(booking: Booking | FunctionBooking) {
  if (!booking) return false;
  return "functionName" in booking;
}

export function getTableNumbersOfBooking(booking: Booking | FunctionBooking) {
  const tableNumbers = [];
  if ("functionName" in booking) {
    return chain(booking.batchingTableGroups)
      .map((t) => t.split(","))
      .flatten()
      .filter((t) => !!t)
      .uniq()
      .value();
  } else {
    const { number } = booking.alg;
    tableNumbers.push(...(number || "").split(","));
    /**Remove letter a or A from table Number */
    return tableNumbers.map((t) => t.trim().replace(/a/gi, ""));
  }
}

export function getActualTableNumber(tableNumber: string, booking: Booking | FunctionBooking) {
  const tableNumbers = getTableNumbersOfBooking(booking);
  if (findIndex(tableNumbers, tableNumber) > -1) {
    return tableNumber;
  }
  for (let i = 0; i < tableNumbers.length; i++) {
    const tn = tableNumbers[i];
    if (tableNumber.slice(0, 1) + "0" + tableNumber.slice(1) === tn) {
      return tn;
    }
  }
  return tableNumber;
}

export function isCustomerSittingOnDifferentBooking(customerId: string, booking: Booking | FunctionBooking) {
  const tableNumbersOfBooking = getTableNumbersOfBooking(booking);
  const customerTableNumber = getUserTableNumber(booking, customerId);
  if (!customerTableNumber) return false;
  if (tableNumbersOfBooking.includes(customerTableNumber)) return false;
  return true;
}

/**
 * Generates generic Booking for the purpose of previewing a given menu
 * @returns Booking
 */
export function generatePreviewBooking(restaurantId: string, mealId: string, menuId: string, zoneId: string, tableNumber: string): Booking {
  const now = moment.tz(zoneId).valueOf();
  return {
    guests: [],
    date: Date.now(),
    tableNumber,
    restaurantId,
    mealId,
    intervalId: now,
    widgetId: "",
    type: BookingType.Preview,
    status: statuses.seated.value,
    menuId,
    courseId: "",
    pax: 1,
    enableOnlineOrdering: true,
    _key: `preview-booking`,
    alg: {
      ref: "",
      number: tableNumber,
      duration: 30, // hard coded to 30 mins
      reset: 0,
      waitlist: null,
      incomplete: false,
      expiry: null,
      onHold: null,
      pax: 1,
      tableNumber,
    },
    endTime: null,
    casual: false,
    customerId: "",
    alternateCustomerId: "",
    dateCreated: Date.now(),
    lastUpdated: Date.now(),
  };
}

export function getAreaIdOfBooking(booking: Booking | FunctionBooking, customerId?: string) {
  let areaId = (booking as Booking).areaId || booking.alg.areaId;
  if (!areaId && customerId) {
    const customerTableNumber = getUserTableNumber(booking, customerId);
    areaId = findKey((booking as FunctionBooking).alg.areaIds, (ids) => ids.split(",").includes(customerTableNumber));
  }
  return areaId;
}
